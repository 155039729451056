.paginationBttns {
  display: flex;
  justify-content: center;
  gap: 5px;
  list-style: none;
  font-size: 15px;
  z-index: 1;
}

.paginationBttns a {
  padding: 8px;
  color: #bdbdbd;
  cursor: pointer;
}

.paginationBttns a:hover {
  opacity: 0.8;
}

.paginationActive a {
  color: #212121;
}

.paginationDisabled a {
  pointer-events: none;
  color: #bdbdbd;
  opacity: 0.8;
}
